/* eslint-disable */
import { createTheme, Theme } from '@material-ui/core'
import { DefaultTheme } from './DefaultTheme'

export const MedicalTheme = (): Theme => {
  const theme = createTheme({
    ...DefaultTheme()
  })

  return theme
}
