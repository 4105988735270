/* eslint-disable */
import React, { FC } from 'react'
import { Theme, ThemeProvider } from '@material-ui/core'
import { DefaultTheme } from './theme/DefaultTheme'
import { MedicalTheme } from './theme/MedicalTheme'

interface ThemeProps {
  variant?: 'default' | 'medical' | 'medical-dark' | 'max-contrast' | 'ruby' | 'travel'
}

export const HuiThemeProvider: FC<ThemeProps> = ({ children, variant }) => {
  const getTheme = (): Theme => {
    switch (variant) {
      case 'default':
        return DefaultTheme()
      case 'medical':
        return MedicalTheme()
    }
    return DefaultTheme()
  }

  return <ThemeProvider theme={getTheme()}>{children}</ThemeProvider>
}
