/* eslint-disable */
import { createTheme, Theme } from '@material-ui/core'

export const DefaultTheme = (): Theme => {
  const theme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            boxShadow: 'none !important'
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true
        }
      },
      MuiInputBase: {
        styleOverrides: {
          inputMultiline: {
            boxShadow: 'none',
            ':focus': { boxShadow: 'none !important' }
          }
        }
      },
      MuiInputLabel: {
        defaultProps: {},
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0, 0.3)'
          }
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: '0.6rem',
            ':hover': { boxShadow: '0 0 0 0.2rem #dee3ec' }
          },
          underline: {
            '::before': { display: 'none' },
            '::after': { display: 'none' }
          },
          input: {
            borderRadius: '0.6rem',
            ':focus': {
              borderRadius: '0.6rem',
              boxShadow: '0 0 0 0.2rem rgba(0, 123, 194, 0.4)'
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            borderRadius: '0.6rem'
            // ":focus": {boxShadow: '0 0 0 0.2rem rgba(0, 123, 194, 0.4)'},
          },
          filled: {
            input: {
              borderRadius: '0.6rem'
              // ":focus": {boxShadow: '0 0 0 0.2rem rgba(0, 123, 194, 0.4)'},
            }
          }
        }
      }
    },
    palette: {
      primary: {
        main: '#20447D',
        light: '#007bff'
      }
    },
    typography: {
      fontFamily: 'OpenSans',
      h1: {
        fontSize: '2.25rem'
        // marginBottom: '0.5rem',
        // '@media (min-width:600px)': {
        //   fontSize: '2.5rem',
        // },
        // [defaultTheme.breakpoints.up('md')]: {
        //   fontSize: '2.5rem'
        // }
      },
      h2: {
        fontSize: '1.8rem',
        fontWeight: 'bold',
        marginBottom: '0.5rem'
      },
      h3: {
        fontSize: '1.575rem',
        fontWeight: 'bold',
        marginBottom: '0.5rem'
      },
      h4: {
        fontSize: '1.35rem',
        fontWeight: 'bold',
        marginBottom: '0.5rem'
      },
      h5: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
        marginBottom: '0.5rem'
      }
    }
  })

  return theme
}
